
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function homeFitmentSearchContainerRT () {
    return _createElement('div', { 'className': 'themevale_multilevel-category-filter' }, _createElement('div', { 'className': 'form-label' }, _createElement('h4', { 'className': 'title' }, 'Select Vehicle'), _createElement('span', {}, '|'), [this.HomeGarage(function () {
            return _createElement('div', { 'className': 'garage-link cm_garage cm_HomeGarage' }, _createElement('div', { 'className': 'navUser-icon' }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-garage' }))), _createElement('div', { 'className': 'items item--garage' }, _createElement('a', { 'className': 'cm_toggle-dropdown navUser-action' }, _createElement('span', { 'className': 'navUser-text' }, 'garage'), _createElement('i', { 'className': 'icon' }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-chevron-down' })))), [this.garageDropdown(function () {
                    function repeatItems1(items, itemsIndex) {
                        return [items(function () {
                                return _createElement('div', { 'className': 'garage-item' }, _createElement('span', {
                                    'className': 'select-model',
                                    'data-cm-role': 'select-vehicle'
                                }, this.vehicleString), _createElement('span', {
                                    'className': 'remove-model',
                                    'data-cm-role': 'remove-vehicle'
                                }, _createElement('i', { 'className': 'icon' }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-close' })))));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'login-dropdown cm_garageDropdown' }, this.template === 'list' ? _createElement('div', {
                        'className': 'cmTemplate_list',
                        'key': '46'
                    }, _createElement('div', {
                        'className': 'clear-garage',
                        'data-cm-role': 'clear-garage'
                    }, 'Clear garage'), _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_items' },
                        _map(this.items, repeatItems1.bind(this))
                    ]), _createElement('div', { 'className': 'garage-content' }, _createElement('div', { 'className': 'vehicle-submit' }, [this.addButton(function () {
                            return _createElement('div', { 'className': 'button cm_addButton' }, [window.Convermax.config?.garageButtonText || 'Add a vehicle']);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]))) : null, this.template === 'empty' ? _createElement('div', {
                        'className': 'cmTemplate_empty',
                        'key': '1322'
                    }, _createElement('div', { 'className': 'garage-content' }, _createElement('div', { 'className': 'vehicle-submit' }, [this.addButton(function () {
                            return _createElement('div', { 'className': 'button cm_addButton' }, [window.Convermax.config?.garageButtonText || 'Add a vehicle']);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]), _createElement('ul', {}, _createElement('li', { 'className': 'vehicle-advantages' }, 'Store your vehicle in the garage ', _createElement('i', { 'className': 'icon' }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-check' })))), _createElement('li', { 'className': 'vehicle-advantages' }, 'Get products for your vehicle ', _createElement('i', { 'className': 'icon' }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-check' })))), _createElement('li', { 'className': 'vehicle-advantages' }, 'Easily find the parts you need ', _createElement('i', { 'className': 'icon' }, _createElement('svg', {}, _createElement('use', { 'xlinkHref': '#icon-check' }))))))) : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]), [this.HomeVehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry3(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry4(entry, index) {
                            return _createElement('div', {
                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, this.showCheckboxes ? _createElement('input', {
                                'type': 'checkbox',
                                'readOnly': true,
                                'checked': entry.selected,
                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                'key': '3609'
                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                'className': 'cm_dropdown_hitCount',
                                'key': '3811'
                            }, entry.hitCount) : null);
                        }
                        return _createElement('div', { 'className': 'form-field ' + this.extraClassName }, !this.isSearchable ? _createElement('div', {
                            'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                            'key': '49'
                        }, _createElement('select', {
                            'className': 'cm_select_inner-select form-select',
                            'onChange': this.onChange,
                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                            'disabled': this.disabled,
                            'aria-label': this.title
                        }, this.useNativeDropdown ? [
                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                'value': '',
                                'key': 'null-option'
                            }, '\n          ', 'Select ', this.title, '\n        ') : null,
                            this.loading ? _createElement('option', {
                                'key': 'loading-option',
                                'disabled': true
                            }, '...loading...') : null,
                            this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                _createElement('option', {
                                    'className': 'cm_option_title',
                                    'disabled': true,
                                    'key': '7911'
                                }, 'Popular Makes'),
                                _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                _createElement('option', {
                                    'className': 'cm_option_title',
                                    'disabled': true,
                                    'key': '7914'
                                }, 'All Makes'),
                                _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                            ] : null,
                            this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                        ] : null, !this.useNativeDropdown ? _createElement('option', {
                            'key': '_current',
                            'value': '_current'
                        }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ 'Select ' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                    return _createElement('div', { 'className': 'form-input cm_filterInput' });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                'aria-label': this.title,
                                'key': '2898'
                            },
                            !this.hideNullOption ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'onClick': () => this.onChange('')
                            }, '\n      ', 'Select ', this.title, '\n    ') : null,
                            this.loading ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'disabled': true
                            }, '...loading...') : null,
                            !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                        ]) : null);
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'form-wrapper cm_model-selector cm_HomeVehicleWidget' }, _createElement.apply(this, [
                'div',
                { 'className': 'selects-container form-wrapper cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'buttons-container' }, _createElement('div', {
                'className': 'cm_button button button--primary',
                'onClick': this.selectVehicle,
                'disabled': !this.allowToSetVehicle
            }, '\n    GO\n  '), _createElement('div', {
                'className': 'cm_button button button--primary',
                'id': 'themevale_clear-select',
                'title': 'Reset',
                'onClick': this.discardVehicle,
                'disabled': !this.allowToDiscardVehicle
            }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-refresh' })))));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:addButton","cm:addButton","cm:garageDropdown","cm:HomeGarage","cm:filterInput","cm:HomeVehicleWidget"]