
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function vehicleLabelContainerRT () {
    return _createElement('div', { 'className': 'cm_model-selector category' }, this.template === 'locked' ? _createElement('div', {
        'className': 'locked-container cmTemplate_locked',
        'key': '43'
    }, _createElement('div', { 'className': 'model-selector-title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'model-selector-subtitle' }, this.fitmentSearchTitle)), _createElement('div', { 'className': 'model-selector-body' }, _createElement('div', { 'className': 'buttons-container' }, _createElement('div', {
        'className': 'cm_button button button--primary',
        'onClick': this.openDialog
    }, 'Change'), _createElement('div', {
        'className': 'button button--primary',
        'id': 'themevale_clear-select',
        'title': 'Reset',
        'onClick': this.discardVehicle
    }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-refresh' })))))) : null);
}
        export const componentNames = []