
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                return _createElement('div', { 'className': 'fitment-row' }, _createElement('div', { 'className': 'fitment-cell fitment-field-year' }, this.Year), _createElement('div', { 'className': 'fitment-cell fitment-field-make' }, this.Make), _createElement('div', { 'className': 'fitment-cell fitment-field-model' }, this.Model));
            }, { count: undefined })];
    }
    return !this.noFitmentData ? _createElement('div', {
        'className': 'cm_fitment-table fitment-table-container',
        'key': '0'
    }, this.manyFitments ? _createElement('div', {
        'className': 'fitment-table-title many-fitments',
        'key': '86'
    }, 'Currently displaying 1000 fitments, yet the product has additional fitments.') : null, this.universalFit ? _createElement('div', {
        'className': 'fitment-table-title universal-fit',
        'key': '244'
    }, 'Universal Fit') : null, _createElement.apply(this, [
        'div',
        { 'className': 'fitment-table-body cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ])) : null;
}
        export const componentNames = []