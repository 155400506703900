import { bigcommerceAppClientId } from '../_common/constants.js';

const baseFitmentFields = ['Year', 'Make', 'Model'];
const ignoreFields = ['Vehicle'];
const categoryVehicleWidgetIgnoreUrls = ['/featured-vehicles/', '/help-center/', '/vehicle/'];
const categoryVehicleWidgetSelector = '.page-type-category, .page-type-page #cm_results';
const onVehicleStorePage = () => !!window.Convermax.fitmentSearch.vehicleStore;
const categorySelectionPageUrl = '/parts/';
const genericVehiclePageUrl = '/vehicle/';

if (!globalThis.Convermax.fitmentSearch) {
  globalThis.Convermax.fitmentSearch = {};
}
globalThis.Convermax.fitmentSearch.vehicleStore = globalThis.__CONVERMAX__?.fitmentSearch?.vehicleStore;

if (!globalThis.Convermax.product) {
  globalThis.Convermax.product = {};
}
globalThis.Convermax.product.category = globalThis.__CONVERMAX__?.product?.category;
globalThis.Convermax.product.dataSlick = globalThis.document.querySelector(
  '#product-related [data-slick]',
)?.dataset.slick;

globalThis.Convermax.categoryItems = globalThis.document.querySelector('.themevale_subCategory')
  ? // eslint-disable-next-line no-unsafe-optional-chaining
    [...globalThis.document.querySelector('.themevale_subCategory')?.querySelectorAll('.item')]
  : null;

function InitFunc() {
  // remove category ymm
  window.document.querySelector('.page-type-category .body .themevale_MultiCategory')?.remove();
}

const customerGroupId = getGroupId();
async function getGroupId() {
  if (!globalThis.cm_currentUserId) {
    return '';
  }
  const groupId = await new Promise((resolve) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200) {
          const jwt = xmlHttp.responseText;
          const [, jsonPart] = jwt.split('.');
          const { customer } = JSON.parse(atob(jsonPart));
          resolve(customer.group_id);
        } else if (xmlHttp.status === 404) {
          resolve(null);
        } else {
          resolve(null);
        }
      }
    };
    xmlHttp.open('GET', `/customer/current.jwt?app_client_id=${bigcommerceAppClientId}`, true);
    xmlHttp.send();
  });
  return groupId || '';
}

function getFitmentSearchTitle() {
  if (document.querySelector('#cm_results') || document.querySelector('#cm_ymm_landing')) {
    return 'parts';
  }
  const categoryTitle = document.querySelector('.page-type-category h1.page-heading');
  if (categoryTitle) {
    return categoryTitle.textContent;
  }
}

function updateCallback() {
  const {
    Convermax: { categoryItems: items, getSearchResponse },
    location,
  } = window;

  if (!items) {
    return;
  }

  const categoryFacet = getSearchResponse().facets.get('category');
  const valueHitsMap = categoryFacet
    ? Object.fromEntries(categoryFacet.values.map((v) => [v.value, v.hitCount]))
    : {};

  items.forEach((item) => {
    const itemTitle = item.querySelector('a.title');
    const categoryLink = itemTitle.pathname;
    if (categoryLink) {
      const { search } = location;
      const urlFacets = search.includes('/') ? search.split('&')[0].replace(/^\?/, '') : '';
      const newFacets = urlFacets && urlFacets.replace(/\/?category\/[^/&]+/, '').replace(/^\//, '');
      itemTitle.href = newFacets ? `${categoryLink}?${newFacets}` : categoryLink;
    }

    const hitsContainer = itemTitle.querySelector('.count');
    if (hitsContainer) {
      const itemText = itemTitle.querySelector('.text');
      const value = itemText && itemText.textContent;
      hitsContainer.textContent = valueHitsMap[value] || 0;
    }
  });
}

function customValuesProcessor(values, { facet: { alwaysShowCount }, showMoreCollapsed, FacetValue }) {
  if (values.length <= alwaysShowCount || !showMoreCollapsed) {
    return values;
  }
  const selectedValues = values.filter((v) => v.isSelected);
  const visibleNotSelectedValuesCount = alwaysShowCount - selectedValues.length;

  const [visibleValues, hiddenValues] = values
    .filter((v) => !v.isSelected)
    .sort((v1, v2) => v2.hitCount - v1.hitCount)
    .reduce(
      ([visible, hidden], value, i) =>
        i < visibleNotSelectedValuesCount ? [[...visible, value], hidden] : [visible, [...hidden, value]],
      [[], []],
    );
  const sortedVisibleValues = values.filter((value) => FacetValue.isInCollection(value, visibleValues));
  return [...selectedValues, ...sortedVisibleValues, ...hiddenValues];
}

function categoryVehicleWidgetVisible() {
  return (
    !onVehicleStorePage() &&
    !categoryVehicleWidgetIgnoreUrls.includes(window.location.pathname) &&
    !!window.document.querySelector(categoryVehicleWidgetSelector)
  );
}

export default {
  platform: 'bigcommerce',
  InitFunc,
  SearchRequestDefaults: {
    pageSize: 48,
    selection: globalThis.Convermax.fitmentSearch.vehicleStore
      ? [{ field: 'Vehicle', term: globalThis.Convermax.fitmentSearch.vehicleStore }]
      : undefined,
    extra: {
      customerGroupId,
    },
  },
  autocomplete: {
    requestDefaults: { extra: { customerGroupId } },
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl ||
      window.location.pathname === genericVehiclePageUrl
        ? 'parts'
        : defaults.getter(),
    typeToSelectorMap: {
      home: '.page-type-default .main.full',
      category: '.page-type-category',
    },
  },
  product: {
    noImageSrc: 'https://fpautoparts.com/product_images/uploaded_images/productdefault.jpg',
  },
  facets: {
    simpleFacet: { fields: ['Part Type', 'Interior Color'], customValuesProcessor },
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    categorySelectionPages: [
      { pathname: genericVehiclePageUrl, field: 'category' },
      { pathname: categorySelectionPageUrl, field: 'category' },
    ],
    categorySelectionPageRedirect: true,
    getFitmentSearchTitle,
    expectResponseRedirect: true,
    isAutoRedirectDisabled: true,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: {
        replace: '.page-type-category .page-content, .page-type-brand .page-content',
        class: 'cm_main-content',
      },
      template: 'MainContent',
    },
    {
      name: 'MiniStoreSearchPage',
      type: 'SearchPage',
      location: {
        lastChildOf: '.page.vehicle-page .category-products',
        class: 'cm_mini-store-search-page',
      },
      template: 'MainContent',
    },
    {
      name: 'MiniStoreVehicleCategories',
      type: 'FacetTiles',
      location: { lastChildOf: '.page.vehicle-page .categories-content-container' },
      template: 'facetTiles/tabs',
      facetField: 'category',
      isMultiLevel: true,
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'HomeFitmentSearchContainer',
      type: 'SearchPage',
      location: { replace: '.main .themevale_MultiCategory', class: 'themevale_MultiCategory' },
      template: 'fitmentSearch/homeFitmentSearchContainer',
      noSearchResults: true,
    },
    {
      name: 'SearchBox',
      location: '.item--quicksearch',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.item--searchMobile .navUser-action',
        wrapper: 'a',
        class: 'navUser-action',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetPanelCategory',
      type: 'FacetPanel',
      location: '.page-type-category #facetedSearch, .page-type-brand #facetedSearch',
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_ymm_landing',
      template: 'facetTiles/hierarchicalContainer',
      isMultiLevel: true,
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select form-select--small',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'MobileChips',
      type: 'FilterChips',
      ignoreFields,
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'RelatedItems',
      location: '#product-related',
      template: 'product/relatedItems',
      count: 5,
      getSelection() {
        const {
          Convermax: { product: { category } = {} },
        } = window;
        if (!category) {
          return null;
        }
        return category
          .map((c) => c.split('/')[0])
          .map((term) => ({ field: 'category', term: term.replace(/&amp;/g, '&') }));
      },
    },
    {
      name: 'FitmentTable',
      location: '#tabCustomProduct-content',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      visibleIf: { selector: 'body.page-type-default' },
      useNativeDropdown: false,
    },
    {
      name: 'CategoryVehicleDropdowns',
      type: 'VehicleWidget',
      location: { firstChildOf: 'div.body' },
      template: 'fitmentSearch/vehicleDropdownsContainer',
      visibleIf: categoryVehicleWidgetVisible,
      useNativeDropdown: false,
    },
    {
      name: 'GenericMiniStoreVehicleLabel',
      type: 'VehicleWidget',
      location: {
        selector: 'body.pages-custom-category-custom-vehicle-pages .hero-block-cat--inner h1',
        class: 'cm_generic-vehicle-label',
      },
      template: 'fitmentSearch/genericMiniStoreVehicleLabel',
      useNativeDropdown: false,
    },
    {
      name: 'GenericMiniStoreChangeButton',
      type: 'VehicleWidget',
      location: {
        lastChildOf: 'body.pages-custom-category-custom-vehicle-pages .hero-block-cat',
        class: 'cm_generic-change-button',
      },
      template: 'fitmentSearch/genericMiniStoreVehicleLabel',
      useNativeDropdown: false,
    },
    {
      name: 'CategoryVehicleLabel',
      type: 'VehicleWidget',
      location: { replace: 'h1.page-heading', class: 'page-heading' },
      template: 'fitmentSearch/vehicleLabelContainer',
      useNativeDropdown: false,
      visibleIf: categoryVehicleWidgetVisible,
    },
    {
      name: 'BrandModelSelector',
      type: 'VehicleWidget',
      location: { insertBefore: '#grid-list-layout' },
      template: 'fitmentSearch/categoryVehicleWidget',
      visibleIf: { selector: '.page-type-brand' },
    },
    {
      name: 'HeaderVehicleWidget_miniStore',
      type: 'VehicleWidget',
      location: { insertAfter: '.themevale_breadcrumbCategory .container' },
      template: 'fitmentSearch/vehicleDropdownsContainer',
      visibleIf: onVehicleStorePage,
      useNativeDropdown: false,
    },
    {
      name: 'HeaderVehicleWidget_miniStore2',
      type: 'VehicleWidget',
      location: { replace: '.themevale_MultiCategory.specialty' },
      template: 'fitmentSearch/vehicleDropdownsContainer',
      visibleIf: onVehicleStorePage,
      useNativeDropdown: false,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      doNotRedirectOnVehicleSelect: true,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertAfter: '.productView .productView-options' },
      template: 'fitmentSearch/verifyFitment',
      useNativeDropdown: false,
    },
    {
      name: 'Garage',
      location: '.themevale_middleHeader .item--garage',
      template: 'fitmentSearch/garage',
    },
    {
      name: 'HomeGarage',
      type: 'Garage',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
