//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-456:_5544,_1967,_2364,_7964,_7132,_3476,_3292,_3504;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-456')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-456', "_5544,_1967,_2364,_7964,_7132,_3476,_3292,_3504");
        }
      }catch (ex) {
        console.error(ex);
      }